import { Grid } from '@mui/material'
import Table from 'src/components/UI/CustomUI/organisms/Table'

import headers from './headers'
import { useSelector } from 'react-redux'
import { getPCFinancials } from 'src/ducks/selectors'
import { round, roundWithoutCents } from 'src/helpers'

const Financials = () => {
  const pcFinancals = useSelector(getPCFinancials())

  const financials = [
    {
      totals: 'Total Active Work Orders',
      count: pcFinancals?.totalActiveWO === undefined ? '-' : pcFinancals?.totalActiveWO,
      isBold: true,
      padding: "4px 8px"
    },
    {
      totals: 'Total $ Active Contract Value',
      count: pcFinancals?.totalActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancals?.totalActiveContractValue)}` : '-',
      align: "right",
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Active Contract Value',
      count: pcFinancals?.avgActiveContractValue !== undefined ? `$${roundWithoutCents(pcFinancals?.avgActiveContractValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Complete per day (past 30 days)',
      count: pcFinancals?.avgCompleteWOValue !== undefined ? `$${roundWithoutCents(pcFinancals?.avgCompleteWOValue)}` : '-',
      padding: "4px 8px"
    },
    {
      totals: 'AVG $ Completed per day (month to date)',
      count: pcFinancals?.avgCompleteWOValue !== undefined ? `$${roundWithoutCents(pcFinancals?.avgCompleteWOValue)}` : '-',
      padding: "4px 8px"
    },
  ]

  return (
    <Grid container item spacing={1} direction="column" style={{ overflow: 'overlay', flexWrap: 'nowrap' }} height='250px'>
      <Grid item>
        <Table
          headers={headers}
          defaultOrder="desc"
          // loading={false}
          hasPagination={false}
        >
          {financials ?? []}
        </Table>
      </Grid>
    </Grid>
  )
}

export default Financials
