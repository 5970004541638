import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { AttachmentsProps } from './types'
import { LabeledText } from 'src/components/UI'
import { Divider } from '../../Templates'
import { useSelector } from 'react-redux'
import { getWorkOrderContractDetails } from 'src/ducks/selectors'

const Details: FC<AttachmentsProps> = () => {
  const contractDetails = useSelector(getWorkOrderContractDetails)

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      height={'100%'}
      style={{ flexWrap: 'nowrap', overflow: 'overlay', marginTop: 0 }}
      bgcolor={"red"}
    >
      <Grid container item>
        <Grid item xs={6}>
          <LabeledText
            labelValue="Pay at Close"
            textValue={`${contractDetails?.payAtClose
              ? 'YES ✅'
              : contractDetails?.payAtClose === false
                ? 'NO ❌'
                : '-'
              }`}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            labelValue="Date Signed:"
            textValue={
              contractDetails?.dateSigned
                ? new Date(contractDetails?.dateSigned)
                  .toISOString()
                  .split('T')[0]
                : '-'
            }
          />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid container item style={{ gap: '4px' }} direction="column">
        <LabeledText labelValue="Customer Information" />
        <LabeledText
          labelValue="Customer:"
          textValue={contractDetails?.customerFullName || '-'}
        />
        <Box display="flex" gap="16px">
          <LabeledText
            labelValue="Phone:"
            textValue={contractDetails?.customerPhone || '-'}
          />
          <LabeledText
            labelValue="Email:"
            textValue={contractDetails?.customerEmail || '-'}
          />
        </Box>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid container item style={{ gap: '4px' }} direction="column">
        <LabeledText labelValue="Agent Details" />
        <Box display="flex" gap="16px">
          <LabeledText
            labelValue="Agent:"
            textValue={contractDetails?.agentFullName || '-'}
          />
          <LabeledText
            labelValue="Brokerage:"
            textValue={contractDetails?.agentBrokerage || '-'}
          />
        </Box>
        <Box display="flex" gap="16px">
          <LabeledText
            labelValue="Phone:"
            textValue={contractDetails?.agentPhone || '-'}
          />
          <LabeledText
            labelValue="Email:"
            textValue={contractDetails?.agentEmail || '-'}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Details
