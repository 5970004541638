import { Box, Tooltip, Typography } from '@mui/material'
import {
  HeaderType,
  AlignType,
} from 'src/components/UI/CustomUI/organisms/Table/types'

import styles from './styles.module.scss'
import { InsertLink, NorthEast } from '@mui/icons-material'
import ShowMore from 'src/components/UI/CustomUI/atoms/ShowMore'
import moment from 'moment'

export const removeProtocol = (url: string): string => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

const headers: HeaderType[] = [
  // {
  //   id: 'workOrderId',
  //   label: 'Work Order ID',
  //   custom: true,
  //   align: 'left' as AlignType,
  //   padding: "0px 8px",
  //   minWidth: 250,
  //   highlightRows: true,
  //   stickyLeft: "0px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <a style={{ cursor: 'pointer' }} href={`/work-orders/${row.id}`}>
  //           <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
  //             {`#${row.id || "-"}`}
  //           </Typography>
  //         </a>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'orderPublicId',
    label: 'Order Public ID',
    custom: true,
    align: 'left' as AlignType,
    minWidth: 105,
    padding: "0px 8px",
    highlightRows: true,
    stickyLeft: "0px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <a style={{ cursor: 'pointer' }} href={`/work-orders/${row.id}`}>
            <Typography className={styles.Companies__companyName} style={{ color: 'var(--blue700)' }}>
              {row.orderPublicId || "-"}
            </Typography>
          </a>
          {/* <Typography className={styles.Companies__companyName}>
            {row.orderPublicId || "-"}
          </Typography> */}
        </Box>
      )
    },
  },
  // {
  //   id: 'estimatePublicId',
  //   label: 'Estimate Public ID',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   highlightRows: true,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.estimatePublicId}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'propertyAddress',
    label: 'Property Address',
    custom: true,
    stickyLeft: "105px",
    align: 'center' as AlignType,
    padding: "0px 8px",
    minWidth: 250,
    highlightRows: true,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '2px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {`${row.propertyAddress}`}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'territory',
    label: 'Territory',
    custom: true,
    align: 'center' as AlignType,
    padding: "0px 8px",
    minWidth: 150,
    highlightRows: true,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '2px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {`${row.territoryName || "-"}`}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'cityStateZip',
  //   label: 'City, State, Zip',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 180,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.cityStateZip}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'estimatedDateOfCompletion',
    label: 'Estimated Date of Completion',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.estimatedDateOfCompletion ? moment(row.estimatedDateOfCompletion).format("MM-DD-YY") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'percWorkOrderComplete',
    label: '% of Work Order Complete',
    custom: true,
    minWidth: 125,
    align: 'center' as AlignType,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.percWorkOrderComplete ? `${row.percWorkOrderComplete}%` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'totalContract',
    label: 'Approved Total',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.totalContract || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'pendingProAssignment',
    label: 'Pro Assignment',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.pendingProAssignment ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'jobStartDate',
    label: "Work Order Scheduled Start Date / Time",
    custom: true,
    align: 'center' as AlignType,
    minWidth: 180,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.jobStartDate ? moment(row.jobStartDate).format("MM-DD-YY hh:mm A") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'acceptedByPro',
    label: 'Accepted By Pro',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.acceptedByPro ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'jobCheckInDateTime',
    label: 'Job Check In Date / Time',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 180,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.jobCheckInDate}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'payAtClose',
    label: 'Pay at Close',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.payAtClose ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'closingDate',
    label: 'Closing Date',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 110,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.closingDate ? moment(row.closingDate).format("MM-DD-YY") : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'changeOrderPendingApproval',
    label: 'Change Order Pending Approval',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 150,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.changeOrderPendingApproval || "-"}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'dispatchOrder',
  //   label: 'Dispatch Order',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 80,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.dispatchOrder ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'receiveVendorResponse',
  //   label: 'Receive Vendor Response',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.receiveVendorResponse ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'reviewWork',
    label: 'Work Order QC Needed',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 120,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.reviewWork ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'clientReview',
    label: 'Customer Satisfaction Received',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.clientReview ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'payVendor',
    label: 'Pay Vendor',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.payVendor ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'markJobComplete',
    label: 'Mark Job Complete',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.markJobComplete ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'pendingSelection',
  //   label: 'Pending Selection',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingSelection}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'pendingQualityControl',
  //   label: 'Pending QC',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingQualityControl}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'payAtClose',
  //   label: 'Pay at Close',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.payAtClose ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'closingDate',
  //   label: 'Closing Date',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.closingDate}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },

  // {
  //   id: 'dateOfOriginalContract',
  //   label: 'Date of Original Contract',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {`${row.dateOfOriginalContract} ${row.proContactPhone}`}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'workItemTotal',
    label: 'Work Item Total',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.workItemTotal}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'notStarted',
    label: 'Not Started',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.notStarted || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'inProgress',
    label: 'In Progress',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 90,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.inProgress}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'workItemComplete',
    label: 'Work Item Complete',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 108,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.workItemComplete}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 150,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.customerName}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'agentName',
    label: 'Agent Name',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 125,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.agentName || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'pendingInvoice',
    label: 'Pending Invoice',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 80,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.pendingInvoice ? 'YES' : 'NO'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'reworkPending',
  //   label: 'Rework Pending',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 80,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.reworkPending ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'pendingCustomerSurvey',
  //   label: 'Pending Customer Survey',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 135,
  //   padding: "0px 8px",
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.pendingCustomerSurvey ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'totalOriginalContractAmount',
    label: 'Total Original Contract Amount',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 135,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.totalContractAmount || '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'totalChangeOrderAmount',
    label: 'Total Change Order Amount',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 135,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.totalChangeOrderAmount || '-'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'changeOrderPendingApproval',
  //   label: 'Change Order Pending Approval',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.changeOrderPendingApproval || "-"}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'amountComplete',
    label: 'Amount Complete',
    custom: true,
    align: 'center' as AlignType,
    minWidth: 135,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.amountComplete ? `${row.amountComplete}` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  {
    id: 'contractAmountPending',
    label: 'Amount Remaining',
    custom: true,
    minWidth: 135,
    padding: "0px 8px",
    align: 'center' as AlignType,
    Element: (row: any): JSX.Element => {
      return (
        <Box style={{ padding: '1px 10px' }}>
          <Typography className={styles.Companies__companyName}>
            {row.contractAmountPending ? `${row.contractAmountPending}` : '-'}
          </Typography>
        </Box>
      )
    },
  },
  // {
  //   id: 'depositReceived',
  //   label: 'Deposit 20% Amount Received',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.depositReceived ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'milestoneOneReceived',
  //   label: 'MileStone 1: 40% Payment Received',
  //   custom: true,
  //   align: 'center' as AlignType,
  //   minWidth: 125,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.milestoneOneReceived ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'milestoneTwoReceived',
  //   label: 'MileStone 2: 30% Payment Received',
  //   custom: true,
  //   minWidth: 125,
  //   align: 'center' as AlignType,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName}>
  //           {row.milestoneTwoReceived ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  // {
  //   id: 'milestoneThreeReceived',
  //   label: 'MileStone 3: 10% Payment Received',
  //   custom: true,
  //   minWidth: 125,
  //   align: 'center' as AlignType,
  //   Element: (row: any): JSX.Element => {
  //     return (
  //       <Box style={{ padding: '1px 10px' }}>
  //         <Typography className={styles.Companies__companyName} style={{ color: row.milestoneThreeReceived ? '#17B975' : '#E01F1F' }}>
  //           {row.milestoneThreeReceived ? 'YES' : 'NO'}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
  {
    id: 'comments',
    label: 'Comments',
    custom: true,
    minWidth: 125,
    align: 'center' as AlignType,
    padding: "0px 8px",
    Element: (row: any): JSX.Element => {
      return (
        (row.comments && row.comments.length > 20) ? <Tooltip
          title={
            <Box style={{ padding: '1px 10px' }}>
              <Typography className={styles.Companies__companyName}>
                {row.comments || '-'}
              </Typography>
            </Box>
          }
          placement='top'
          enterDelay={500}
          leaveDelay={200}
          arrow
        >
          <Box style={{ padding: '1px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.comments ? row.comments.slice(0, 20) + '...' || '-' : "-"}
            </Typography>
          </Box>
        </Tooltip>
          : <Box style={{ padding: '1px 10px' }}>
            <Typography className={styles.Companies__companyName}>
              {row.comments || '-'}
            </Typography>
          </Box>
      )
    },
  },
]

export default headers
