/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { FetchPCPortfolioCount, FetchPCPortfolioSummary, pcViewTypes } from './types'
import { pcViewActions } from '../actions'

export function* fetchPCPortfolioSummary({ payload, callback }: FetchPCPortfolioSummary): SagaIterator {
  let success = false
  try {
    const portfolioSummary = yield call(Punchlist.pcView.getPortfolioSummary, payload)

    if (portfolioSummary) {
      yield put(pcViewActions.setPCView({ portfolioSummary }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the pc view portfolio summary'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPCPortfolioCount({ payload, callback }: FetchPCPortfolioCount): SagaIterator {
  let success = false
  try {
    const portfolioCount = yield call(Punchlist.pcView.getPortfolioCount, payload)

    if (portfolioCount) {
      yield put(pcViewActions.setPCView({ portfolioCount }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the pc view portfolio summary'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPCWorkOrders({ payload, callback }: FetchPCPortfolioCount): SagaIterator {
  let success = false
  try {
    const workOrders = yield call(Punchlist.pcView.getPCWorkOrders, payload)

    if (workOrders) {
      yield put(pcViewActions.setPCView({ workOrders }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the pc view work orders'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPCDetails({ payload, callback }: FetchPCPortfolioCount): SagaIterator {
  let success = false
  try {
    const pcDetails = yield call(Punchlist.pcView.getPCDetails, payload)

    if (pcDetails) {
      yield put(pcViewActions.setPCView({ pcDetails }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    // yield call(toast.error, errorTextTryingTo('get the pc view details'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPCFinancials({ payload, callback }: FetchPCPortfolioCount): SagaIterator {
  let success = false
  try {
    const pcFinancials = yield call(Punchlist.pcView.getPCFinancials, payload)

    if (pcFinancials) {
      yield put(pcViewActions.setPCView({ pcFinancials }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    // yield call(toast.error, errorTextTryingTo('get the pc view details'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(pcViewTypes.FETCH_PC_PORTFOLIO_SUMMARY, fetchPCPortfolioSummary)
  yield takeLatest(pcViewTypes.FETCH_PC_PORTFOLIO_COUNT, fetchPCPortfolioCount)
  yield takeLatest(pcViewTypes.FETCH_PC_WORK_ORDERS, fetchPCWorkOrders)
  yield takeLatest(pcViewTypes.FETCH_PC_DETAILS, fetchPCDetails)
  yield takeLatest(pcViewTypes.FETCH_PC_FINANCIALS, fetchPCFinancials)
}
