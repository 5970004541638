import storeInfo from '../store'
import { PCViewType } from './types'

export const getPCView = () => (): PCViewType => {
  const { pcView } = storeInfo.store.getState()
  return pcView
}

export const getPCPortfolioSummary = () => () => {
  const { pcView: { portfolioSummary } } = storeInfo.store.getState()

  return portfolioSummary
}

export const getPCPortfolioCount = () => () => {
  const { pcView: { portfolioCount } } = storeInfo.store.getState()

  return portfolioCount
}

export const getPCWorkOrders = () => () => {
  const { pcView: { workOrders } } = storeInfo.store.getState()

  return workOrders
}

export const getPCDetails = () => () => {
  const { pcView: { pcDetails } } = storeInfo.store.getState()

  return pcDetails
}

export const getPCFinancials = () => () => {
  const { pcView: { pcFinancials } } = storeInfo.store.getState()

  return pcFinancials
}
