import { useSelector } from 'react-redux'

import { Box, TableRow as MaterialTableRow } from '@mui/material'

import { Row, TableRowProps } from './types'
import TableCell from '../TableCell'
import { HeaderType } from '../types'
import TableAction from '../TableAction'
import { Loader } from '../../../atoms'

import styles from './styles.module.scss'
import { ActionType } from '../TableAction/types'
import { getEstimate, getJob } from 'src/ducks/selectors'
import { position } from 'html2canvas/dist/types/css/property-descriptors/position'

const TableRow = <T extends Row, U>({
  children,
  headers,
  actions,
  onClickRow,
  small,
  hasBorders,
  index,
  highlightedRows,
  rowColor = () => '',
  onRightClickRow,
  selected
}: TableRowProps<T, U>) => {
  const cells: JSX.Element[] = []

  const job = useSelector(getJob())
  const estimate = useSelector(getEstimate())

  headers.forEach((cell: HeaderType, cellIndex: number) => {
    const customCell = cell.custom
    const cellOdd = cell.highlightRows && !(index % 2 === 0)
    !cell.hide && cells.push(
      <TableCell
        key={`cell-${cellIndex}`}
        dataType={cell.type}
        maxWidth={cell.maxWidth ? `${cell.maxWidth}px` : 'auto'}
        width={cell.width ? `${cell.width}px` : 'auto'}
        small={small}
        minWidth={cell.minWidth ? `${cell.minWidth}px` : 'auto'}
        className={`${styles.TableCell} ${!hasBorders && styles.TableCell__noBorders}`}
        align={cell.align ? cell.align : 'left'}
        color={cell.columnColor ? cell.columnColor(children) : ''}
        sx={{ position: cell.stickyLeft ? "sticky" : "relative", zIndex: cell.stickyLeft ? 2 : 0, left: cell.stickyLeft ? cell.stickyLeft : 0, padding: cell.padding ? cell.padding : '6px !important', backgroundColor: cell.stickyLeft ? cellOdd ? "var(--gray200)" : "#fff" : rowColor(children) }}
      >
        {customCell
          ? cell.Element ? cell.Element(children, index, (job?.properties || estimate?.properties)?.totalValue === 0) : 'no type'
          : children[cell.id as keyof T] as unknown as number | string | boolean}
      </TableCell>)
  })

  if (actions) {
    cells.push(
      <TableCell
        key='cell-actions'
        dataType='action'
        align='center'
        sx={{ backgroundColor: rowColor(children) }}
      >
        <Box>
          {actions.map((action: ActionType<any>) => {
            return (
              <TableAction
                key={action.id}
                row={children}
              >
                {action}
              </TableAction>
            )
          })}
        </Box>
      </TableCell>
    )
  }

  if (children.loading) {
    return (
      <MaterialTableRow key='row-1'>
        <TableCell key='loader' colSpan={cells.length}>
          <Loader />
        </TableCell>
      </MaterialTableRow>
    )
  } else {
    return (
      <>
        <MaterialTableRow
          key='row-1'
          onContextMenu={(e) => {
            if (onRightClickRow) {
              onRightClickRow(children, e)
            }
          }}
          selected={selected}
          onClick={(e) => onClickRow && onClickRow(children, e)} className={`${styles.TableRow} ${onClickRow && styles.TableRow__clickable} ${highlightedRows ? styles.TableRow__highlightedRows : ''}`}
        >
          {cells}
        </MaterialTableRow>
      </>
    )
  }
}

export default TableRow
