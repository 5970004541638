import { PCView } from '../types'

/** TYPES **/
export const pcViewTypes = {
  FETCH_PC_PORTFOLIO_SUMMARY: 'FETCH_PC_PORTFOLIO_SUMMARY',
  FETCH_PC_PORTFOLIO_COUNT: 'FETCH_PC_PORTFOLIO_COUNT',
  FETCH_PC_WORK_ORDERS: 'FETCH_PC_WORK_ORDERS',
  FETCH_PC_DETAILS: 'FETCH_PC_DETAILS',
  FETCH_PC_FINANCIALS: 'FETCH_PC_FINANCIALS',
  SET_PC_VIEW_ACTION: 'SET_PC_VIEW_ACTION',
}

/** ACTIONS **/
export interface FetchPCPortfolioSummary {
  type: typeof pcViewTypes.FETCH_PC_PORTFOLIO_SUMMARY
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchPCPortfolioCount {
  type: typeof pcViewTypes.FETCH_PC_PORTFOLIO_COUNT
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchPCWorkOrders {
  type: typeof pcViewTypes.FETCH_PC_WORK_ORDERS
  payload: {
    id: string
    openOrClosed?: string | null,
    workOrderStatus?: string | null,
    jobStatus?: string | null,
    fromDate?: number | null,
    toDate?: number | null,
    start?: number,
    limit?: number
    territory?: string | null,
    territoryManager?: string | null,
    successManager?: string | null,
    affiliate?: string | null,
    affiliateType?: string | null
    paymentStatus?: string | null
  }
  callback?: (succ: boolean) => void
}

export interface FetchPCDetails {
  type: typeof pcViewTypes.FETCH_PC_DETAILS
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface FetchPCFinancials {
  type: typeof pcViewTypes.FETCH_PC_FINANCIALS
  payload: {
    id: string
  }
  callback?: (succ: boolean) => void
}

export interface SetPCViewAction {
  type: typeof pcViewTypes.SET_PC_VIEW_ACTION
  payload: Partial<PCView>
}

export type PCViewActions =
  | FetchPCPortfolioSummary
  | FetchPCPortfolioCount
  | FetchPCWorkOrders
  | FetchPCDetails
  | FetchPCFinancials
  | SetPCViewAction

/** REDUCER **/
export type PCViewType = PCView
