import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'src/assets'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getEstimate } from 'src/ducks/selectors'
import {
  Box,
  BoxContainer,
  Button,
  CircularProgress,
  TitleFirst,
  Typography,
} from '../../../../UI'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { estimatesActions } from 'src/ducks/actions'
import PortfolioSummary from './Tabs/PortfolioSummary'
import PortfolioCounts from './Tabs/PortfolioCounts'
import Details from './Tabs/Details'
import Financials from './Tabs/Financials'

const EstimateTopInfo: FC<{ loading: boolean, children: React.ReactNode }> = ({ loading, children }) => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())

  const [status, setStatus] = useState(estimate?.status)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [toDeleteIdx, setToDeleteIdx] = useState<number>()

  const pcViewTabs = [
    { label: 'Details', content: <Details /> },
    { label: 'Financials', content: <Financials /> },
  ]

  const files = estimate?.properties?.files
    ?.filter((file) => !file.fileType?.includes('GOOGLE_SEARCH_IMAGE'))
    .map((file, idx) => {
      return {
        name: file.name,
        onDeleteClick: () => {
          setToDeleteIdx(idx)
          setConfirmationModal(true)
        },
        onViewClick: () => {
          window.open(file.fileUrl, '_blank')
        },
      }
    })

  useEffect(() => {
    setStatus(estimate?.status)
  }, [estimate])

  const handleDelete = () => {
    if (estimate?.properties?.address)
      dispatch(
        estimatesActions.updateEstimate(
          {
            properties: {
              ...estimate?.properties,
              files: estimate?.properties?.files?.filter(
                (e, idx) => idx !== toDeleteIdx
              ),
            },
          },
          (succ: boolean) => {
            setConfirmationModal(false)
          }
        )
      )
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      justifyContent="space-between"
      style={{ maxWidth: "97vw" }}
    >
      <Modal
        setOpen={setConfirmationModal}
        open={confirmationModal}
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, ml: 2 }}>
            Confirm Delete
          </Typography>
        }
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">
            Are you sure you want to delete this attachment?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <icons.Delete />
              )
            }
            variant="containedError"
            color="error"
            onClick={handleDelete}
            sx={{ ml: 2, color: 'red' }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <BoxContainer
        sx={{ width: '100%', height: '190px', justifyContent: 'flex-start', padding: "10px 8px" }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Project Coordinator View"
              blackVariant="h5"
              lineHeight="24px"
            />
          </Box>
        }
        loading={loading}
      >
        <Details />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '190px', justifyContent: 'flex-start', padding: "10px 8px" }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Financials"
              blackVariant="h5"
              lineHeight="24px"
            />
          </Box>
        }
        loading={loading}
      >
        <Financials />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '190px', justifyContent: 'flex-start', padding: "10px 8px" }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Portfolio Counts by Status"
              blackVariant="h5"
              lineHeight="24px"
            />
          </Box>
        }
        loading={loading}
      >
        <PortfolioCounts />
      </BoxContainer>
      {children}
    </Box>
  )
}

export default EstimateTopInfo
