import { PCViewActions, pcViewTypes, PCViewType } from './types'

const actions = {
  fetchPCPortfolioSummary: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_PORTFOLIO_SUMMARY,
    payload,
    callback,
  }),

  fetchPCPortfolioCount: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_PORTFOLIO_COUNT,
    payload,
    callback,
  }),

  fetchPCWorkOrders: (
    payload: {
      id: string,
      openOrClosed?: string | null,
      workOrderStatus?: string | null,
      jobStatus?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      start?: number,
      limit?: number
      territory?: string | null,
      territoryManager?: string | null,
      successManager?: string | null,
      affiliate?: string | null,
      affiliateType?: string | null
      paymentStatus?: string | null
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_WORK_ORDERS,
    payload,
    callback,
  }),

  fetchPCDetails: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_DETAILS,
    payload,
    callback,
  }),

  fetchPCFinancials: (
    payload: {
      id: string
    },
    callback?: (succ: boolean) => void
  ): PCViewActions => ({
    type: pcViewTypes.FETCH_PC_FINANCIALS,
    payload,
    callback,
  }),

  setPCView: (payload: PCViewType): PCViewActions => ({
    type: pcViewTypes.SET_PC_VIEW_ACTION,
    payload,
  }),
}

export default actions
